.loginBox {
  width: 100%;
  max-width: 400px;
  margin: 50px auto 0;

  .logo {
    display: block;
    width: 150px;
    margin: 0 auto 20px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}