.date {
  font-size: 16px;
  font-weight: 600;
}

.hypen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.homeTeam,
.visitorTeam {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  font-size: 18px;
  font-weight: 600;

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}

.homeTeam {
  text-align: right;
}

.width100 {
  width: 100% !important;
}

.actions {
  text-align: right;
}