.hypen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.homeTeam {
  text-align: right;
}

.width100 {
  width: 100% !important;
}

.season {
  text-align: right;
}

.actions {
  text-align: right;
}