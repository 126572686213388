.team {
  margin-bottom: 20px !important;

  .teamContent {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100px;
        max-height: 100px;
      }
    }

    .name {
      width: 100%;
      margin: 0 20px;
      white-space: nowrap;
      font-size: 18px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.match {
  margin-bottom: 20px !important;

  .matchContent {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .date {
      width: 200px;
    }

    .name {
      width: 100%;
      margin: 0 20px;
      white-space: nowrap;
      font-size: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}