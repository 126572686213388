.layout {
  min-height: 100vh !important;
}

.header {
  background: #fff !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px !important;

  .logo {
    width: 50px;
    margin-right: 50px;
  }

  .topMenu {
    width: 100%;

    ul {
      line-height: 64px;
    }
  }

  .seasons {
    display: flex;
    white-space: nowrap;
    align-items: center;
    margin-right: 20px;

     > span {
      margin-right: 5px;
    }

     > button {
      margin-left: 5px;
    }
  }

  .adminMode {
    white-space: nowrap;
    margin-right: 20px;
  }

  .email {
    margin-right: 20px;
    font-weight: 600;
  }
}

.addSeasonForm {
  display: flex;
  align-items: center;

  > div {
    margin: 0;

    input {
      height: 24px;
      width: 100px;
    }
  }

  button {
    margin-left: 5px;
  }
}

.content {
  background: #fff;
  margin: 50px 50px 0;
  padding: 40px;
}

.footer {
  text-align: center;
}

@media (max-width: 991px) {
  .header {
    padding: 0 20px !important;

    .logo {
      margin-right: 0;
    }

    .topMenu {
      ul {
        width: 64px; // collapse menu

        li:first-child {
          border-bottom-color: transparent;
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }

    .seasons {
      > span {
        display: none;
      }
    }

    .email {
      display: none;
    }
  }

  .content {
    margin: 20px;
    padding: 20px;
  }
}