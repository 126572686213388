.leaderboard {
  max-width: 600px;
  margin: 30px auto !important;
}

.teamLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2em;
  height: 3em;

  &:nth-of-type(1) {
    font-size: 2em;
  }

  &:nth-of-type(2) {
    font-size: 1.7em;
  }

  &:nth-of-type(3) {
    font-size: 1.5em;
  }

  .icon {
    font-size: 2em;
    width: 150px;
    text-align: center;
  }

  .team {
    width: 100%;
    font-weight: 600;
    margin-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .points {
    font-size: 0.8em;
    width: 100px;
    text-align: right;
    font-weight: 600;
  }

  .smallPoints {
    font-size: 0.8em;
    opacity: 0.5;
    width: 100px;
    text-align: left;
    margin-left: 10px;
  }
}

.round {
  width: 100%;
  margin-bottom: 20px;

  .header {
    span {
      display: block;
      white-space: nowrap;
    }
  }

  td {
    padding: 0 !important;

    > span {
      display: block;
      border-right: 1px solid white;
      font-weight: 600;
      padding: 10px;
      white-space: nowrap;
    }
  }
}

@media (max-width: 991px) {
  .leaderboard {
    max-width: none;
    margin: 0 !important;
    width: 100%;
  }

  .teamLine {
    &:nth-of-type(1) {
      font-size: 1.2em;
    }

    &:nth-of-type(2) {
      font-size: 1.2em;
    }

    &:nth-of-type(3) {
      font-size: 1.2em;
    }

    .icon {
      font-size: 2em;
      width: 120px;
      text-align: center;
    }

    .points {
      font-size: 1em;
    }

    .smallPoints {
      font-size: 1em;
    }
  }

  .round {
    width: 100%;
    margin-bottom: 20px;

    .header {
      span {
        display: block;

        &:last-child {
          font-weight: 400;
        }
      }
    }
  }
}