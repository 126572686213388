.round {
  h3 {
    margin: 20px 0;
  }

  .match {
    display: flex;
    flex-direction: column;
    height: calc(100% - 20px);
    margin-bottom: 20px;

    > div {
      height: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .bids {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .bid {
        border-top: 1px solid #f0f0f0;
        background: #fafafa;
        display: flex;
        align-items: center;
        font-weight: 600;
        padding: 5px;

        span {
          width: 100%;
          text-align: center;

          &:first-of-type {
            text-align: left;
          }
        }
      }
    }
  }

  .addMatchCard {
    height: calc(100% - 20px);
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .teams {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;

    .name {
      display: inline-block;
      width: 100%;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 5px;

      &:first-child {
        text-align: right;
      }

      &:last-child {
        text-align: left;
      }
    }

    img {
      max-width: 50px;
      max-height: 50px;
    }
  }

  .results {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px 15px;
    min-height: 50px;

    > span {
      width: calc(50% - 15px);
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-of-type {
        justify-content: flex-end;
      }

      &:last-of-type {
        justify-content: flex-start;
      }
    }

    .result {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(0, 0, 0, 0.65);
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.05);
      height: 50px;
      width: 50px;
    }
  }

  .spacer {
    display: inline-block;
    width: 30px !important;
  }
}