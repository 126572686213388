.logo {
  display: block;
  width: 150px;
  margin: 0 auto 20px;
}

.formBox {
  width: 100%;
  max-width: 400px;
  margin: 50px auto 0;

  .title {
    margin-bottom: 30px;
    font-size: 24px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}